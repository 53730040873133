import React from "react"

import dhl  from "./images/dhl.svg"
import fedex  from "./images/FedEx_Express.svg"
import dpd  from "./images/DPD.svg"
import hermes  from "./images/Hermes.svg"
import parcelforce  from "./images/parcelforce.svg"
import ups  from "./images/UPS.svg"
import royalmail  from "./images/RoyalMail.svg"
import yodel  from "./images/Yodel.svg"
import p2p  from "./images/P2P.svg"
import anpost  from "./images/Anpost.svg"
import toyou  from "./images/toyou.svg"
import ukmail  from "./images/ukmail.svg"
import windirect  from "./images/WnDirect.svg"

import styles from "./styles.module.scss"

const OurClients = () => (
  <div className={styles.clientImages}>
    <img src={dhl} className={styles.dhl} alt="carrier logo" />
    <img src={fedex} className={styles.fedex} alt="carrier logo" />
    <img src={dpd} className={styles.dpd} alt="carrier logo" />
    <img src={hermes} className={styles.hermes} alt="carrier logo" />
    <img src={parcelforce} className={styles.parcelforce} alt="carrier logo" />
    <img src={ups} className={styles.ups} alt="carrier logo" />
    <img src={royalmail} className={styles.royalmail} alt="carrier logo" />
    <img src={yodel} className={styles.yodel} alt="carrier logo" />
    <img src={p2p} className={styles.p2p} alt="carrier logo" />
    <img src={anpost} className={styles.anpost} alt="carrier logo" />
    <img src={toyou} className={styles.toyou} alt="carrier logo" />
    <img src={ukmail} className={styles.ukmail} alt="carrier logo" />
    <img src={windirect} className={styles.windirect} alt="carrier logo" />
  </div>
)

export default OurClients











